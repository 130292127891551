import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../box';
import { Box } from '../box';

export const ModalBody = React.forwardRef<HTMLDivElement, BoxT>(
  ({ className, ...rest }, ref) => (
    <Box
      ref={ref}
      className={clsx(
        ['w-full', 'flex-auto', 'overflow-auto', 'px-8', 'pt-4', 'pb-8'],
        className,
      )}
      {...rest}
    />
  ),
);
