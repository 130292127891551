import useSWR from 'swr';
import { formatPriceData } from '@mentimeter/plans-content';
import { core } from '@mentimeter/http-clients';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { DEFAULT_PRICE, type LocalPricingT } from '@mentimeter/billing';

interface UsePricesT {
  isLoading: boolean;
  prices: LocalPricingT;
  error: Error | null;
}

export function usePrices(): UsePricesT {
  const isProd = process.env.NODE_ENV === 'production';
  const route = '/prices/self-service';

  const { data: prices, error } = useSWR(
    route,
    async () => {
      try {
        const { data } = await core().prices.get();
        return formatPriceData(data);
      } catch (error) {
        // Check if it's a timeout error since we've had issues with this
        // Once we can determine that it's stable, we can remove this special error handling
        const isTimeout =
          error instanceof Error &&
          (error.name === 'TimeoutError' ||
            error.message.toLowerCase().includes('timeout') ||
            (error as any)?.code === 'ECONNABORTED');

        const fetchError = new MentiError(
          isTimeout ? 'Price fetching timed out' : 'Price fetching failed',
          {
            feature: 'payments',
            tags: {
              route,
              errorMessage:
                error instanceof Error ? error.message : String(error),
              isTimeout: String(isTimeout),
            },
            cause: error,
          },
        );
        captureException(fetchError);
        return DEFAULT_PRICE;
      }
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: 300000, // 5 minutes
      errorRetryCount: isProd ? 2 : 1,
    },
  );

  return {
    isLoading: !prices && !error,
    prices: prices || DEFAULT_PRICE,
    error: error || null,
  };
}
