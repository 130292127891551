import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Overlay } from '@radix-ui/react-dialog';
import React from 'react';

const FelaDialogRadixOverlayStyled = styled(
  Overlay,
  {
    displayName: 'DialogRadixOverlayStyled',
  },
  true,
)(() => ({}), 'color');

export type ModalOverlayT = React.ComponentProps<
  typeof FelaDialogRadixOverlayStyled
>;

export const ModalOverlay = React.forwardRef((props: ModalOverlayT, ref) => {
  const defaultClasses = [
    'bg-overlay',
    'top-0',
    'right-0',
    'bottom-0',
    'left-0',
    'fixed',
    'z-modal',
    'data-[state=open]:motion-safe:animate-[fadeIn_300ms_ease-out]',
    'data-[state=closed]:motion-safe:animate-[fadeOut_300ms_ease-out]',
  ];

  const { className, ...rest } = props;

  return (
    <FelaDialogRadixOverlayStyled
      {...rest}
      ref={ref}
      className={cn(defaultClasses, className)}
    />
  );
});
