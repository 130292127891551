import { useExperiment } from '@mentimeter/splitio';
import { useUser } from '@mentimeter/user';

export function useRecommendPlanPaywall() {
  const { user } = useUser();
  const { isInExperiment: showRecommendPlanPaywall } = useExperiment(
    'PUG_Recommend_Best_Plan',
    ['v1'],
  );

  const freeUser = user?.isFreeUser;

  return {
    showRecommendPlanPaywall: showRecommendPlanPaywall && freeUser,
  };
}
