/* eslint-disable menti-react/filename-convention--jsx */
'use client';

import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import type { DefaultValidKeyCodesT } from '@mentimeter/hotkeys';
import { HotkeyHandler } from '@mentimeter/hotkeys';
import { v4 as uuid } from 'uuid';
import { useInterval, useTimeout } from '@mentimeter/react-hooks';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Text } from '@mentimeter/ragnar-ui/text';

export interface DisplayToastT {
  description: React.ReactNode;
  autoDismiss: boolean;
  action?: ButtonT | undefined;
  hotkey?:
    | {
        onKeyUp: () => void;
        keyCode: DefaultValidKeyCodesT;
      }
    | undefined;
  hideLoaderBar?: boolean | undefined;
  hideDismissIcon?: boolean | undefined;
  variant?: 'info' | 'positive' | 'notice' | 'negative' | undefined;
}

type ToastT = DisplayToastT & {
  onDismiss: () => void;
};

type ItemT = ToastT & {
  id: string;
};

export interface ToastContextT {
  items: Array<ItemT>;
  displayToast: (toast: DisplayToastT) => () => void;
}

export const ToastsContext = React.createContext<ToastContextT>({
  items: [],
  displayToast: () => () => {},
});

export const ToastsProvider = ({ children }: { children: React.ReactNode }) => {
  const [items, setItems] = React.useState<Array<ItemT>>([]);

  const removeToast = React.useCallback((id: string) => {
    setItems((prev) => prev.filter((x) => x.id !== id));
  }, []);

  const displayToast = React.useCallback(
    ({
      description,
      autoDismiss,
      action,
      hotkey,
      hideLoaderBar,
      hideDismissIcon,
      variant,
    }: DisplayToastT) => {
      const id = uuid();

      setItems((prev) => [
        ...prev,
        {
          id,
          description,
          autoDismiss,
          action,
          hotkey,
          hideLoaderBar,
          hideDismissIcon,
          variant,
          onDismiss: () => {
            removeToast(id);
          },
        },
      ]);
      return () => {
        removeToast(id);
      };
    },
    [removeToast],
  );

  return (
    <ToastsContext.Provider value={{ items, displayToast }}>
      {children}
    </ToastsContext.Provider>
  );
};

export const useToast = (): ToastContextT => {
  const context = React.useContext(ToastsContext);
  if (!context) throw Error(`useToast must be used inside of a ToastsProvider`);
  return context;
};

export const Toasts = () => {
  const { items } = useToast();

  return (
    <Box
      position="fixed"
      mt={['space1', 'space8']}
      px={['space1', 'space0']}
      width={['100%', '600px']}
      left="50%"
      alignItems="center"
      extend={({ theme }) => ({
        zIndex: theme.zIndex.notification,
        transform: 'translateX(-50%)',
        pointerEvents: 'auto',
      })}
      id="toasts"
    >
      {items.map((item, index) => (
        <Toast
          key={`${item.id}:${index}`}
          description={item.description}
          action={item.action}
          hotkey={item.hotkey}
          autoDismiss={item.autoDismiss}
          onDismiss={item.onDismiss}
          hideDismissIcon={item.hideDismissIcon}
          hideLoaderBar={item.hideLoaderBar}
          variant={item.variant}
        />
      ))}
    </Box>
  );
};

const DISMISS_TIME = 6000;
const LOADER_UPDATE_RATE = 2;
const BG_VARIANT_MAP = {
  info: 'infoWeak',
  positive: 'positiveWeak',
  notice: 'noticeWeak',
  negative: 'negativeWeak',
};
const ON_VARIANT_MAP = {
  info: 'onInfoWeak',
  positive: 'onPositiveWeak',
  notice: 'onNoticeWeak',
  negative: 'onNegativeWeak',
};

const Toast = ({
  description,
  onDismiss,
  autoDismiss,
  action,
  hotkey,
  hideLoaderBar = false,
  hideDismissIcon = false,
  variant = 'info',
}: ToastT) => {
  const loaderRef = React.useRef<HTMLDivElement | null>(null);
  const [date] = React.useState(Date.now());
  const increaseWidth = () => {
    if (loaderRef?.current) {
      const newDate = Date.now();

      loaderRef.current.style.width = `${
        ((newDate - date) / DISMISS_TIME) * 100
      }%`;
    }
  };
  useInterval(increaseWidth, LOADER_UPDATE_RATE);
  useTimeout(onDismiss, autoDismiss ? DISMISS_TIME : null);

  return (
    <Box flex="1 1 auto" overflow="hidden">
      <Box
        mt={1}
        p={3}
        flexDirection="row"
        maxWidth="100%"
        borderRadius="xl"
        width={['100%', 'auto']}
        justifyContent="space-between"
        flex="1 1 auto"
        bg={BG_VARIANT_MAP[variant]}
      >
        {!hideDismissIcon && (
          <Clickable
            mt="1px"
            mr="space3"
            aria-label="dismiss notification"
            onClick={onDismiss}
          >
            <HotkeyHandler keyCode="escape" onKeyUp={onDismiss} />
            <CrossIcon color={ON_VARIANT_MAP[variant]} />
          </Clickable>
        )}

        <Text
          color={ON_VARIANT_MAP[variant]}
          fontSize="87.5"
          mb="space0"
          mr="space2"
        >
          {description}
        </Text>

        {action && (
          <>
            <Button variant="subtle" autoFocus {...action} />
            {hotkey && <HotkeyHandler {...hotkey} />}
          </>
        )}
      </Box>
      {!hideLoaderBar && action && (
        <Box ref={loaderRef} flexDirection="row" bg="primary" height="3px" />
      )}
    </Box>
  );
};
