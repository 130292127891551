import { Device } from '@mentimeter/ragnar-device';
import type { EngagementStatus } from '@mentimeter/paywalls-data-hooks';
import {
  ENGAGEMENT_COPY,
  useEngagementLimits,
} from '@mentimeter/paywalls-data-hooks';
import { type TrackingContext } from '@mentimeter/http-clients';
import { useUser } from '@mentimeter/user';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { EngagementBannerUpgradeButton } from '../../../buttons';
import { shouldShowEngagementLimitBanner } from '../../shouldShowEngagementLimitBanner';
import type { UpgradeEvent } from '../../../types';

export function CurrentEngagementLimitBanner({
  upgradeEvent,
  trackingContext,
}: {
  upgradeEvent: UpgradeEvent;
  trackingContext: TrackingContext | undefined;
}) {
  const { user } = useUser();
  const engagementLimits = useEngagementLimits();

  if (!user || !engagementLimits) return null;

  const {
    engagementData: { limit, progress },
    engagementStatus,
  } = engagementLimits;

  if (!shouldShowEngagementLimitBanner(user.createdAt, engagementStatus))
    return null;

  return (
    <Box
      height="72px"
      position="relative"
      width="100%"
      data-testid="engagement-limit-banner"
    >
      <Box position="fixed" width="100%" height="72px" zIndex={4}>
        <Box
          bg="primary"
          position="relative"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          width="100%"
          height="100%"
          px={3}
          id="engagement-limit-banner"
        >
          <Device.Match greaterThan={1}>
            <Box position="absolute" top="-10px" left="-10px">
              <BrandIllustrationCircle />
            </Box>
          </Device.Match>
          <Box flexDirection={[null, 'column', 'row']} alignItems="center">
            <Text color="onPrimary" fontWeight="bold">
              {bannerHeading(engagementStatus, limit, progress)}
            </Text>
            <Text color="onPrimary" ml={1}>
              {bannerPrompt(engagementStatus, limit, progress)}
            </Text>
          </Box>
          <Box ml={3} justifyContent="center">
            <EngagementBannerUpgradeButton
              upgradeEvent={upgradeEvent}
              trackingContext={trackingContext}
              showStarIcon
            />
          </Box>
          <Device.Match greaterThan={1}>
            <Box position="absolute" bottom="0" right="0">
              <BrandIllustrationBars />
            </Box>
          </Device.Match>
        </Box>
      </Box>
    </Box>
  );
}

function bannerPrompt(
  engagementStatus: EngagementStatus,
  limit: number,
  progress: number,
) {
  return ENGAGEMENT_COPY.banner.prompt[engagementStatus](limit, progress);
}

function bannerHeading(
  engagementStatus: EngagementStatus,
  limit: number,
  progress: number,
) {
  return ENGAGEMENT_COPY.banner.heading[engagementStatus](limit, progress);
}

function BrandIllustrationCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="60"
      viewBox="0 0 84 60"
      fill="none"
    >
      <circle cx="30" cy="6" r="54" fill="#FFC738" />
    </svg>
  );
}

function BrandIllustrationBars() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M32 0H48V48H32V0Z" fill="#FF80AB" />
      <rect x="16" y="16" width="16" height="32" fill="#FF80AB" />
      <rect y="32" width="16" height="16" fill="#FF80AB" />
    </svg>
  );
}
