import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../../box';
import {
  variants,
  type BadgeIconContainerVariantProps,
} from './BadgeIconContainer.variants';

interface BadgeIconContainerProps extends BadgeIconContainerVariantProps {
  children: React.ReactNode;
  className?: string;
}

export function BadgeIconContainer({
  size,
  children,
  className,
}: BadgeIconContainerProps) {
  const classes = variants({ size });

  return <Box className={clsx(classes, className)}>{children}</Box>;
}
