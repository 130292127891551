import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../box';
import { Box } from '../box';

export const PopoverBody = React.forwardRef<HTMLDivElement, BoxT>(
  ({ className, ...rest }, ref) => (
    <Box
      ref={ref}
      className={clsx(
        'w-full flex-auto px-4 py-2 my-2 overflow-auto',
        className,
      )}
      {...rest}
    />
  ),
);
