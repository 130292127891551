import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { BADGE_MAP } from './badgeMap';
import type { BadgeT } from './badge.types';
import { variants } from './Badge.variants';
import { textVariants, BadgeIconContainer } from './components';
import { badgeLabel } from './Badge.utils';

export const Badge = React.forwardRef(
  (
    {
      type,
      compact,
      description,
      text,
      textTransform,
      className,
      ...props
    }: BadgeT,
    ref,
  ) => {
    const { title: TitleFromMap, description: descriptionFromMap } =
      BADGE_MAP[type];

    const tooltipDescription = description ?? descriptionFromMap;
    const classes = variants({ variant: type });
    const size = compact ? 'compact' : 'default';

    return (
      <Tooltip
        trigger={
          <Box
            aria-label={badgeLabel(type, text)}
            className={clsx(classes, className)}
            ref={ref}
            {...props}
          >
            {typeof TitleFromMap === 'string' && (
              <Text
                className={clsx(
                  'text-inherit',
                  textVariants({ size, textTransform }),
                )}
              >
                {type === 'text' && text ? text : TitleFromMap || 'Unknown'}
              </Text>
            )}
            {typeof TitleFromMap === 'function' && (
              <BadgeIconContainer size={size}>
                <TitleFromMap size={compact ? 0 : 1} color="currentColor" />
              </BadgeIconContainer>
            )}
          </Box>
        }
      >
        {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
        {tooltipDescription}
      </Tooltip>
    );
  },
);
