import { LocalStorage } from '@mentimeter/storage';
import { core } from '@mentimeter/http-clients';
import { z } from 'zod';

type Keys = 'preferredLanguage' | 'country' | 'visitorToken';

const SPLIT_KEY_PREFIX = 'data-split-';
const VISITOR_TOKEN_KEY = 'visitorToken';

export const getSplitDataSync = (): {
  preferredLanguage: string | null;
  country: string | null;
} => {
  return {
    preferredLanguage: LocalStorage.getItem(
      SPLIT_KEY_PREFIX + 'preferredLanguage',
    ),
    country: LocalStorage.getItem(SPLIT_KEY_PREFIX + 'country'),
  };
};

export function getSplitDataFromLocalstorage<K extends Keys>(
  keys: Array<K>,
): {
  [key in K]?: string | null;
} {
  const valuesFromLocalstorage = keys.map((key) =>
    LocalStorage.getItem(SPLIT_KEY_PREFIX + key),
  );

  if (valuesFromLocalstorage.includes(null)) return {};

  return keys.reduce(
    (prev, curr, index) => ({
      ...prev,
      [curr]: valuesFromLocalstorage[index],
    }),
    {},
  );
}

/**
 * Tries to retrive values from localstorage given the keys. If one isn't stored in localstorage it
 * fetches values from mentimeter.com/split-data and stores it in localstorage
 */
export async function getSplitData<K extends Keys>(
  keys: Array<K>,
): Promise<{ [key in K]?: string | null }> {
  const valuesFromLocalstorage = getSplitDataFromLocalstorage(keys);

  // All of the values are defined in the local storage cache, return.
  if (Object.keys(valuesFromLocalstorage).length === keys.length)
    return valuesFromLocalstorage;

  let shouldIncludeVisitorTokenInResponse = false;
  if ((keys as Keys[]).includes('visitorToken')) {
    const visitorToken = getVisitorToken();
    shouldIncludeVisitorTokenInResponse = visitorToken === null;
  }
  try {
    const { data } = await core().get(
      `/split-data${
        shouldIncludeVisitorTokenInResponse ? '?include_visitor_token' : ''
      }`,
      {
        timeout: 5000,
      },
    );

    keys.forEach((key) => {
      LocalStorage.setItem({
        key: SPLIT_KEY_PREFIX + key,
        value: data[key] || 'none',
        type: 'functional',
      });
    });
    return data;
  } catch (_e) {
    return {};
  }
}

export type VisitorToken = string | null;
export const VISITOR_LOCAL_STORAGE_KEY = SPLIT_KEY_PREFIX + VISITOR_TOKEN_KEY;

export const getVisitorToken = (): VisitorToken => {
  const value = LocalStorage.getItem(VISITOR_LOCAL_STORAGE_KEY);

  // visitorToken can't be 'none', we'll generate it ourselves or request from Splitio in this case
  if (value === 'none') return null;

  return value ?? null;
};

const VISITOR_TOKEN_SCHEMA = z.object({
  visitorKey: z.string(),
});

export function parseVisitorKeyWellEnough(visitorToken: string): string | null {
  try {
    const decoded = JSON.parse(
      Buffer.from(visitorToken.split('.')[1]!, 'base64').toString(),
    );
    const result = VISITOR_TOKEN_SCHEMA.safeParse(decoded);

    if (result.success) {
      return result.data.visitorKey;
    }

    return null;
  } catch (e) {
    return null;
  }
}
