import type { ReactNode } from 'react';
import {
  CrossIcon,
  ExternalLinkIcon,
  StarSolidIcon,
} from '@mentimeter/ragnar-visuals';
import { Button } from '@mentimeter/ragnar-ui/button';
import {
  ModalRoot,
  ModalContainer,
  ModalBody,
  ModalFooter,
  ModalDismiss,
} from '@mentimeter/ragnar-ui/modal';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Link } from '@mentimeter/ragnar-ui/link';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Badge } from '@mentimeter/ragnar-ui/badge';
import { PaywallModalTrigger } from '../../triggers/PaywallModalTrigger';

interface USP {
  text: string;
  icon: React.ReactNode;
}

export function RecommendPlanModalContent({
  title,
  description,
  renderDescriptionOverride,
  onClickUpgrade,
  onClickViewPlans,
  buttonLabel,
  planName,
  price,
  mainUsp,
  href,
  open,
  onOpenChange,
  children,
}: {
  title: string;
  description: string | null;
  renderDescriptionOverride?: ReactNode;
  onClickUpgrade: () => void;
  onClickViewPlans: () => void;
  buttonLabel: string;
  planName: string;
  price: string | null;
  mainUsp: USP[];
  href: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
}) {
  function handleTrigger() {
    onOpenChange(true);
  }

  return (
    <ModalRoot open={open} onOpenChange={onOpenChange}>
      <PaywallModalTrigger trigger={handleTrigger}>
        {children}
      </PaywallModalTrigger>
      <ModalContainer width="1020px">
        <ModalDismiss asChild>
          <Clickable
            p="space4"
            position="absolute"
            right={0}
            top={0}
            zIndex={1}
          >
            <CrossIcon size={3} />
          </Clickable>
        </ModalDismiss>
        <ModalBody px={['space8', 'space8', 'space16']} py="space12">
          <Box
            flexDirection={['column', 'column', 'row']}
            width="100%"
            alignItems="center"
          >
            <Box flex="1" flexDirection="column" pr={[null, null, 'space16']}>
              <Text
                mb="8px"
                fontSize={['20px', '20p', '28px']}
                lineHeight="heading"
              >
                {title}
              </Text>
              {renderDescriptionOverride ? (
                renderDescriptionOverride
              ) : (
                <Text
                  fontSize={['16px', '16px', '20px']}
                  color="textWeaker"
                  mb="space6"
                >
                  {description}
                </Text>
              )}
            </Box>

            <Box
              flex="1"
              borderWidth={1}
              borderStyle="solid"
              flexDirection="column"
              bg="brandWeakest"
              borderColor="borderBrandWeakest"
              borderRadius="xl"
              px={['space6', 'space6', 'space8']}
              py={['space8', 'space8', 'space10']}
            >
              <Box flexDirection="row" alignItems="center" mb="8px">
                <Text fontSize={['20px', '20px', '28px']} mr="space4">
                  {planName.charAt(0).toUpperCase() + planName.slice(1)}
                </Text>
                <Badge bg="brandWeak" type="text" text="Recommended" compact />
              </Box>
              <Box flexDirection="row" mb="space8" alignItems="center">
                <Text mr="space1" fontSize={['16px', '16px', '20px']}>
                  {price}
                </Text>
                <Text color="textWeaker">per presenter/month</Text>
              </Box>
              <Box
                gap={['space3', 'space3', 'space4']}
                flexDirection="column"
                flexWrap="wrap"
              >
                {mainUsp.map((usp, index) => (
                  <Box flexDirection="row" alignItems="center" key={index}>
                    {usp.icon}
                    <Text ml="space4" fontSize={['14px', '14px', '16px']}>
                      {usp.text}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Link
                href="/plans"
                target="_blank"
                underline={false}
                onClick={onClickViewPlans}
              >
                <Box
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  mb="space8"
                  ml="space8"
                  mt="space4"
                >
                  <Text fontWeight="semiBold" mr="space1">
                    View all features
                  </Text>
                  <ExternalLinkIcon />
                </Box>
              </Link>

              <ModalFooter p="0">
                <Button
                  size="large"
                  width="100%"
                  variant="primary"
                  href={href}
                  target="_blank"
                  onClick={onClickUpgrade}
                  iconLeading={StarSolidIcon}
                >
                  {buttonLabel}
                </Button>
              </ModalFooter>
            </Box>
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalRoot>
  );
}
