import React, { useState, type ReactNode } from 'react';
import { gtmTrack } from '@mentimeter/google-tracking';
import { trackUser } from '@api/tracking/client';
import type {
  TrackingContext,
  TrackingPlacement,
} from '@mentimeter/http-clients';
import {
  ColorBucketIcon,
  DownloadIcon,
  ImageIcon,
  PresentationIcon,
  QAIcon,
  ResultsChartIcon,
  SmartphoneIcon,
  UnlockIcon,
  UnorderedListIcon,
  UsersIcon,
} from '@mentimeter/ragnar-visuals';
import { usePrices } from '@mentimeter/plans-ui';
import { getFormattedPrice } from '@mentimeter/billing';
import { useOnce } from '@mentimeter/react-hooks';
import { trackOpenPaywall } from '../tracking/track-open-paywall';
import type { UpgradeEvent } from '../../types';
import { RecommendPlanModalContent } from './modal/RecommendPlanModalContent';

interface USP {
  text: string;
  icon: React.ReactNode;
}
interface Variant {
  title: string;
  buttonLabel: string;
  planName: string;
  mainUsp: USP[];
  href: string;
  description: string | null;
}

const VARIANTS = {
  excel: {
    title: 'Get your data organized in Excel.',
    buttonLabel: 'Upgrade to Basic',
    planName: 'basic',
    description:
      'The Free plan limits what you can do with results after a presentation. Download your results into Excel with Basic.',
    mainUsp: [
      {
        text: 'Download results to Excel to make your own analyses and gather insights',
        icon: <ResultsChartIcon />,
      },
      {
        text: 'Present to unlimited participants',
        icon: <UsersIcon />,
      },
    ],

    href: '/app/checkout/basic',
  },
  import: {
    title: 'Use PowerPoint, Keynote, or  Google Slides in your Menti.',
    buttonLabel: 'Upgrade to Basic',
    planName: 'basic',
    description:
      'The Free plan limits your slide integration options. Get access to more with Basic.',
    mainUsp: [
      {
        text: 'Import slides from Powerpoint, Google Slides or Keynote',
        icon: <DownloadIcon />,
      },
      {
        text: 'Present to unlimited participants',
        icon: <UsersIcon />,
      },
    ],
    href: '/app/checkout/basic',
  },
  shared: {
    title: 'Share custom, reusable templates with your team.',
    buttonLabel: 'Upgrade to Pro',
    planName: 'pro',
    description:
      'The Free plan limits you to standard, pre-made templates. Customize and share templates with Pro.',
    mainUsp: [
      {
        text: 'Create faster with reusable team templates',
        icon: <PresentationIcon />,
      },
      {
        text: 'Apply your brand themes, colors and icons',
        icon: <ColorBucketIcon />,
      },
      {
        text: 'Present to unlimited participants',
        icon: <UsersIcon />,
      },
    ],
    href: '/app/checkout/pro',
  },
  customization: {
    title: 'Customize your Menti.',
    buttonLabel: 'Upgrade to Pro',
    planName: 'pro',
    description:
      'The Free plan limits you to standard, pre-made colors and templates. Change your logo, typeface, colors, and backgrounds with Pro.',
    mainUsp: [
      {
        text: 'Add your own company logo and branding',
        icon: <ImageIcon />,
      },
      {
        text: 'Create custom colors & themes',
        icon: <ColorBucketIcon />,
      },
      {
        text: 'Create faster with reusable team templates',
        icon: <PresentationIcon />,
      },
      {
        text: 'Present to unlimited participants',
        icon: <UsersIcon />,
      },
    ],
    href: '/app/checkout/pro',
  },
  moderation: {
    title: 'Unlock control and flexibility in Menti.',
    buttonLabel: 'Upgrade to Pro',
    planName: 'pro',
    description:
      'The Free plan limits Q&A moderation and language settings. Get full control over what your audience sees with Pro.',
    mainUsp: [
      {
        text: 'Gather audience information in Quick Form',
        icon: <UnorderedListIcon />,
      },
      {
        text: 'Let multiple participants share a device for input',
        icon: <SmartphoneIcon />,
      },

      {
        text: 'Approve or dismiss Q&A questions',
        icon: <QAIcon />,
      },
      {
        text: 'Present to unlimited participants',
        icon: <UsersIcon />,
      },
    ],
    href: '/app/checkout/pro',
  },
  activated: {
    title: 'Unlock unlimited participants.',
    buttonLabel: 'Upgrade to Basic',
    planName: 'basic',
    description: null,
    mainUsp: [
      {
        text: 'Allow unlimited participants to join',
        icon: <UnlockIcon />,
      },
      {
        text: 'Host unlimited number of sessions',
        icon: <PresentationIcon />,
      },
    ],
    href: '/app/checkout/basic',
  },
  reached: {
    title: 'You’ve reached your monthly participant limit.',
    buttonLabel: 'Upgrade to Basic',
    planName: 'basic',
    description: null,
    mainUsp: [
      {
        text: 'Allow unlimited participants to join',
        icon: <UnlockIcon />,
      },
      {
        text: 'Host unlimited number of sessions',
        icon: <PresentationIcon />,
      },
    ],
    href: '/app/checkout/basic',
  },
} as const satisfies Record<string, Variant>;

export function RecommendPlanModal({
  renderDescriptionOverride,
  trackingContext,
  trackingPlacement,
  variant,
  open: openExternal,
  onOpenChange,
  upgradeEvent,
  children,
}: {
  renderDescriptionOverride?: ReactNode;
  trackingContext: TrackingContext | undefined;
  trackingPlacement: TrackingPlacement;
  variant: keyof typeof VARIANTS;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  upgradeEvent: UpgradeEvent;
  children: ReactNode;
}) {
  const { prices: dynamicPrices } = usePrices();
  const [openInternal, setOpenInternal] = useState(false);

  const { title, description, planName, mainUsp, href, buttonLabel } =
    VARIANTS[variant];

  const price = dynamicPrices
    ? getFormattedPrice(
        dynamicPrices?.['plans'][planName].amount,
        dynamicPrices?.currency.symbol,
        dynamicPrices?.format,
      )
    : '';

  // We need to use the open prop to control the modal from the outside
  const open = openExternal || openInternal;
  const setOpen = (newState: boolean) => {
    setOpenInternal(newState);
    onOpenChange?.(newState);
  };

  useOnce(open, () => {
    onOpenPaywall();
  });

  return (
    <RecommendPlanModalContent
      title={title}
      description={description}
      renderDescriptionOverride={renderDescriptionOverride}
      planName={planName}
      price={price}
      mainUsp={mainUsp}
      buttonLabel={buttonLabel}
      href={href}
      onClickUpgrade={trackClickUpgrade}
      onClickViewPlans={trackClickViewPlans}
      open={open}
      onOpenChange={setOpen}
    >
      {children}
    </RecommendPlanModalContent>
  );

  function trackClickUpgrade() {
    gtmTrack({
      event: 'clickInteraction',
      type: 'upgradeFromApp',
    });

    trackUser({
      event: 'Clicked contextual upgrade button',
      properties: {
        'paywall plan': planName,
        trackingContext,
        trackingPlacement,
      },
    });
  }

  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Self upgrade',
      paywallType: 'Recommend plan',
    });
  }

  function trackClickViewPlans() {
    trackUser({
      event: upgradeEvent,
      properties: {
        context: trackingPlacement,
      },
    });
  }
}
