import { useMatch } from '@mentimeter/ragnar-device';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '@mentimeter/ragnar-ui/button';

export function RequestUpgradeButton(buttonProps: ButtonT) {
  const isMobile = useMatch({ lessThan: 2 });

  return (
    <Button {...buttonProps}>{isMobile ? 'Upgrade' : 'Request upgrade'}</Button>
  );
}
