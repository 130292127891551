import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  ['bg-neutral-weak', 'w-full', 'rounded-2xl', 'overflow-hidden', 'relative'],
  {
    variants: {
      variant: {
        circle: ['h-[40px]', 'w-[40px]', 'rounded-full'],
        image: ['h-[100px]'],
        rectangle: ['h-[40px]'],
        text: ['inline-block', 'text-100', 'leading-normal', 'align-sub'],
        heading: ['inline-block', 'text-150', 'leading-snug', 'align-sub'],
      },
    },
  },
);
